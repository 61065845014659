import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["range", "number"];

    connect() {
        this.syncInputs(); // Ensure inputs are synced on load
    }

    syncInputs() {
        this.numberTarget.value = this.rangeTarget.value;
    }

    updateFromRange() {
        this.numberTarget.value = parseInt(this.rangeTarget.value);
    }

    updateFromNumber() {
        const value = parseInt(this.numberTarget.value, 10);

        // Ensure the value is within the range limits
        if (value >= this.rangeTarget.min) {
            this.rangeTarget.value = value;
        }
    }

    resetInvalidNumber() {
        const value = parseInt(this.numberTarget.value, 10);

        // Ensure the value is within the range limits
        if (value < this.rangeTarget.min) {
            this.numberTarget.value = this.rangeTarget.value; // Reset to valid value
        }

    }
}